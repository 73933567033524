var successMixins = {
  methods: {
    success(title, toast) {
      this.$swal({
        toast,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title,
      });
    },
  },
};

export default successMixins;
