<template>
  <b-container fluid>
    <b-row class="h-100 justify-content-center">
      <b-col sm="6" md="5" lg="4" xl="3">
        <!-- zireplus_logo -->
        <form class="form-signin text-center" @submit.prevent="login">
          <img class="img-fluid m-4" src="@/assets/img/zireplus_logo.png" width="40%" />
          <h3 class="mb-3 font-weight-bolder">Login</h3> 
          <div class="d-flex flex-row" v-if="message">
            <b-icon icon="exclamation-circle-fill" variant="danger" class="errorMsg"></b-icon>
            <p class="text-danger">{{message}}</p>
          </div>

          <b-form-group>
            <b-form-input
              trim
              type="email"
              v-model="$v.form.body.email.$model"
              :state="validateState('email')"
              placeholder="Email Address"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-form-input
              trim
              type="password"
              placeholder="Password"
              v-model="$v.form.body.password.$model"
              :state="validateState('password')"
            ></b-form-input>
          </b-form-group>
          <b-form-checkbox
            class="text-left pt-2 pb-3 pt-1"
            size="sm"
            v-model="form.staySignedIn"
            required
          >
            Keep me signed in
          </b-form-checkbox>
          <b-button variant="primary" block type="submit" :disabled="isBusy">
            <b-spinner small v-if="isBusy"></b-spinner>
            <span v-else>Submit</span>
          </b-button>
        </form>

        <b-button
          size="sm"
          variant="link"
          class="float-right pt-3 text-dark font-weight-bold text-decoration-none"
        >
          Forgot Password?
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import errorMixins from "../mixins/notifications/error";
import successMixins from "../mixins/notifications/success";
import { required, email, minLength } from "vuelidate/lib/validators";
import Vue from "vue"
import { getMemberList } from "@/services/profile";
import router from "../router";

export default {
  name: "Login",
  mixins: [errorMixins, successMixins],
  data() {
    return {
      isBusy: false,
      message:null,
      form: {
        body: {
          email: "",
          password: ""
        },
        staySignedIn: false,
        errors: {}
      }
    };
  },
  validations: {
    form: {
      body: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(6)
        }
      }
    }
  },
  methods: {
    login() {
      this.$v.form.body.$touch();
      if (!this.$v.form.body.$anyError) {
        this.isBusy = true;
        this.$store
          .dispatch("auth/login", {
            body: { ...this.form.body },
            fetchUser: false,
            staySignedIn: this.form.staySignedIn
          })
          .then(async (response) => {
            if (response.status === 200 && response.data[0].status.code === 0) {
              await getMemberList("zire-plus-dashboard").then(profiles => {
                const activeUser = profiles.filter((user) => user.uniqueId === response.data[0].objectList[0].userUniqueId) 
                if (activeUser.length === 0){
                  this.message = "No access to Zireplus dashboard.Please inform your admin to invite you." 
                  // this.$bvToast.toast(
                  //   `Please inform your admin to invite you to the ZirePlus dashboard `,
                  //   {
                  //     title: "User not found",
                  //     autoHideDelay: 3000,
                  //     variant: "danger"
                  //   }
                  // );
                  return Vue.auth.logout();
                }
                else{
                  router.push({ name: "summary" })
                }
              })
              this.$auth.user(response.data[0].objectList[0]);
              // this.$auth.user(response.data[0].objectList[0]);
            }else{
              // this.message = "Please inform your admin to invite you to the ZirePlus dashboard" 
              this.message = "User has no access to Zireplus dashboard"
              // this.$bvToast.toast(
              //   `User has no access to Zireplus dashboard`,
              //   {
              //     title: "User not found",
              //     autoHideDelay: 3000,
              //     variant: "danger"
              //   }
              // );
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form.body[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style scoped>
.errorMsg{
  margin-top: 7px;
}
</style>
