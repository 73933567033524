var errorMixins = {
  methods: {
    errorHandler(res) {
      const code = res.status;

      let title = "Error";
      let text = "something weird happened, please try again";
      let status = 0;

      if (Object.prototype.hasOwnProperty.call(res.data, "errors")) {
        Object.values(res.data.errors).forEach(error => {
          text = error;
        });
      } else {
        const errorObject = JSON.parse(res.data.message);
        if (typeof errorObject === "object" && errorObject !== null) {
          if (Object.prototype.hasOwnProperty.call(errorObject, "result")) {
            text = errorObject.result.description;
          }
        } else {
          text = res.data.message;
        }
      }

      switch (code) {
        case 401:
          title = "Unauthorized Access";
          status = `<small>${code}</small>`;
          break;
        case 422:
          title = "Error";
          status = `<small>${code}</small>`;
          break;
        case 405:
          title = "Not allowed";
          text = "Something weird has happened, please try again";
          status = `<small>${code}</small>`;
          break;

        default:
          status = `<small>${code}</small>`;
          break;
      }

      this.error(title, text, status);
    },
    error(title, text, footer) {
      this.$swal({
        icon: "error",
        title,
        text,
        footer,
        confirmButtonText: "OKAY",
        customClass: {
          confirmButton: "btn-block bg-dark"
        }
      });
    }
  }
};

export default errorMixins;
